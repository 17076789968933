<template>
  <div class="form-content-container">
    <Form class="form-container">
      <div class="form-item">
        <div class="title">
          <Input
            placeholder="请输入专著名称(5-30字)"
            v-model.trim="formValidate.title"
            maxlength="30"
          />
        </div>
        <div class="number">{{ formValidate.title.length }}/30</div>
      </div>
      <div class="form-item">
        <div class="checkbox" style="align-items: center">
          <label for="">封面</label>
          <PictureUpload
            @handleUpload="handleUpload"
            :cover="formValidate.cover"
          ></PictureUpload>
        </div>
        <span class="xing">*</span>
      </div>
      <OtherAuthor
        @autherInfo1="handleAuther1"
        :formValidate="formValidate"
      ></OtherAuthor>
      <div class="form-item">
        <Input
          type="textarea"
          :rows="5"
          placeholder="请输入内容简介"
          v-model.trim="formValidate.artSummary"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input v-model.trim="formValidate.press" placeholder="请输入出版社" />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <DatePicker
          type="month"
          placeholder="请输入出版时间"
          style="width: 200px"
          @on-change="changeTime"
          :options="endStartStr"
          :value="formValidate.publishTime"
          :editable="false"
        ></DatePicker>
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input v-model.trim="formValidate.isbn" placeholder="请输入ISBN" />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input v-model.trim="formValidate.edition" placeholder="请输入版次" />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          v-model.trim="formValidate.pageNumber"
          type="number"
          placeholder="请输入页数"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          v-model.trim="formValidate.monographLenght"
          placeholder="请输入字数"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          v-model.trim="formValidate.classifyNumber"
          placeholder="请输入分类号"
        />
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <div>
          <div id="toolbar-container" class="toolbar"></div>
          <div id="text-container" class="text"></div>
        </div>
        <span class="xing">*</span>
      </div>
      <div class="form-item" @click="handleFileDel">
        <div class="upload">
          <label for="">附件：</label>
          <Upload
            type="drag"
            :action="actionUrl"
            :format="['pdf']"
            :headers="{ token: token }"
            :on-success="handleSuccess"
            accept=".pdf"
            :show-upload-list="false"
          >
            <Button>上传</Button>
            <p style="margin-left: 7px" v-if="!formValidate.accessoryName">
              请上传pdf格式文件
            </p>
            <p style="margin-left: 7px" v-else>
              {{ formValidate.accessoryName }}
            </p>
          </Upload>
        </div>
        <span class="xing">*</span>
      </div>
      <div class="form-item">
        <Input
          v-model.trim="formValidate.externalUrl"
          placeholder="请输入外部链接便于平台审核"
        />
      </div>
      <div class="form-item">
        <label for="" style="height: 60px;white-space:nowrap;">发布栏目：</label>
        <div class="checkbox" style="flex-direction: column">
          <CheckboxGroup v-model="columnNum">
            <Checkbox disabled :label="columnNum[0]"></Checkbox>
            <Checkbox disabled :label="columnNum[1]"></Checkbox>
          </CheckboxGroup>
          <Tree
            :data="treeList"
            show-checkbox
            @on-check-change="changeCheck"
          ></Tree>
        </div>
      </div>
    </Form>
    <div class="submit-container">
      <div class="btns">
        <Button
          :disabled="disableFlag"
          @click="handlePreview(2, '/monographpreview')"
          >预览</Button
        >
        <Button @click="handleSave(formValidate)" v-throttleClick="2000"
          >保存</Button
        >
        <Button @click="handleSubmit(formValidate)" v-throttleClick="2000"
          >发布</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
// import E from "@/utils/wangEditor.js";
import axios from "axios";
import PictureUpload from "../components/PictureUpload.vue";
import { mapState } from "vuex";
import OtherAuthor from "../components/OtherAuthor.vue";
import preview from "@/mixins/preview.js";
export default {
  components: {
    PictureUpload,
    OtherAuthor,
  },
  mixins: [preview],
  created() {
    // // 认证
    if (this.userInfo.user.customerType === 1) {
      this.isFlag = true;
    }
    this.projectAndColumnListByType({
      type: 4,
    });
    if (this.$route.query.id) {
      this.getEcho(this.formValidate);
      this.disableFlag = false;
    }
  },
  mounted() {
    this.editor();
  },
  data() {
    return {
      disabledStr: "",
      tempAutherList: [],
      isFlag: false,
      tempArr: [],
      treeList: [], //发布栏目
      actionUrl: `${this.$global.url1}/zw-public/web/common/upload`, // 接口路径
      formValidate: {
        title: "",
        cover: "",
        artSummary: "",
        press: "",
        publishTime: "",
        isbn: "",
        edition: "",
        pageNumber: "",
        monographLenght: "",
        classifyNumber: "",
        catalogue: "",
        externalUrl: "",
        filePath: "",
        accessoryName: "",
        // monographAuthor: "",
        interest: [],
        synchronizedStatus: false,
        articleAuthorList: [],
        autherList: [
          {
            authorName: "",
            authorUnit: "",
            showSelect1: false,
            unitDisabled1: true,
            authorUnitId: "",
            authorId: "",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState(["token", "userInfo"]),
    columnNum() {
      let arr = [];
      if (sessionStorage.getItem("isSon") === "false") {
        if (this.userInfo.user.customerType === 4) {
          arr = ["百科号"];
        } else if (this.userInfo.user.customerType === 5) {
          arr = ["机构号"];
        } else if (
          this.userInfo.user.customerType === 1 ||
          this.userInfo.user.customerType === 2
        ) {
          arr = ["百家号"];
        }
      } else {
        arr = ["百家号", "学者空间"];
      }
      return arr;
    },
    endStartStr() {
      return {
        disabledDate: (data) => {
          let d = new Date(data).valueOf();
          return data && new Date().valueOf() < d;
        },
      };
    },
  },
  methods: {
    editor() {
      var E = window.wangEditor
      this.editor = new E("#toolbar-container", "#text-container"); // 传入两个元素
      let that = this;
      // 上传图片
      this.editor.config.customUploadImg = async function (
        resultFiles,
        insertImgFn
      ) {
        let config = {
          headers: { "Content-Type": "multipart/form-data" },
        };
        // resultFiles 是 input 中选中的文件列表
        let param = new FormData();
        param.append("file", resultFiles[0]);
        const resp = await axios.post(
          that.$global.url1 + "/zw-public/web/common/upload",
          param,
          config
        );
        // 上传图片，返回结果，将图片插入到编辑器中
        insertImgFn(that.$global.imgUrl + resp.data.content.url);
      };
      // 上传视频
      this.editor.config.customUploadVideo = async function (
        resultFiles,
        insertVideoFn
      ) {
        let config = {
          headers: { "Content-Type": "multipart/form-data" },
        };
        // resultFiles 是 input 中选中的文件列表
        let param = new FormData();
        param.append("file", resultFiles[0]);
        const resp = await axios.post(
          that.$global.url1 + "/zw-public/web/common/upload",
          param,
          config
        );
        insertVideoFn(that.$global.imgUrl + resp.data.content.url);
      };
      this.editor.config.onchange = function (html) {
        // 第二步，监控变化，同步更新到 textarea
        that.formValidate.catalogue = html;
      };
      this.editor.config.placeholder = "请输入目录";
      this.editor.create();
      // // 第一步，初始化 textarea 的值
      this.formValidate.catalogue = this.editor.txt.html();
    },
    verification(formValidate) {
      for (let i = 0; i < formValidate.articleAuthorList.length; i++) {
        const element = formValidate.articleAuthorList[i];
        if (!this.$route.query.id && element.authorName == "") {
          this.$Message.error("其他作者不能为空");
          return false;
        }
      }
      if (this.formValidate.title.length < 5) {
        this.$Message.error("标题不能少于五个字");
        return false;
      }
      let reg = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+)\.)+([A-Za-z0-9-~])+$/;
      if (!reg.test(formValidate.externalUrl) && formValidate.externalUrl !== '') {
        this.$Message.error("外部链接格式不正确");
        return false;
      }
      if (/[^\w\u4E00-\u9FA5.]/.test(formValidate.monographLenght)) {
        this.$Message.error("字数格式不正确");
        return false;
      }
      if (formValidate.artSummary.length > 2500) {
        this.$Message.error("内容简介超出限制");
        return false;
      }
      return true;
    },
    // 保存
    async handleSave(formValidate) {
      if (this.isEmpty(formValidate)) {
        return false;
      }
      if (this.tempArr.length === 0) {
        this.treeList.forEach((item) => {
          if (item.children && item.children.length > 0) {
            item.children.forEach((v) => {
              if (v.checked) {
                this.tempArr.push(v);
              }
            });
          } else {
            if (item.checked) {
              this.tempArr.push(item);
            } else {
              this.tempArr = [];
            }
          }
        });
      }
      if (!this.verification(formValidate)) {
        return false;
      }
      formValidate.interest = [];
      this.tempArr.forEach((e) => {
        if (e.children && e.children.length > 0) {
          return false;
        }
        formValidate.interest.push({
          projectId: e.menuIds || null,
          channelId1: e.id || null,
        });
      });
      const resp = await this.$apis.formServe.webSave({
        auditStatus: 1,
        articleType: 2,
        projectId:
          JSON.parse(sessionStorage.getItem("Table")) &&
          JSON.parse(sessionStorage.getItem("Table")).PlatformEnum[2].id,
        articleId: this.$route.query.id || this.previewData.articleId,
        cover: formValidate.cover,
        monographName: formValidate.title,
        artSummary: formValidate.artSummary,
        press: formValidate.press,
        publishTime: formValidate.publishTime + "-01",
        isbn: formValidate.isbn,
        edition: formValidate.edition,
        pageNumber: formValidate.pageNumber,
        monographLenght: formValidate.monographLenght,
        classifyNumber: formValidate.classifyNumber,
        catalogue: formValidate.catalogue,
        externalUrl: formValidate.externalUrl,
        filePath: formValidate.filePath,
        accessoryName: formValidate.accessoryName,
        articlePushList: this.tempArr.length > 0 ? formValidate.interest : [],
        articleAuthorList:
          formValidate.articleAuthorList &&
          formValidate.articleAuthorList.length > 0
            ? formValidate.articleAuthorList
            : this.tempAutherList, // 其他作者
        synchronizedStatus: formValidate.synchronizedStatus === true ? 1 : 0,
      });
      if (resp.status === 200) {
        this.previewData = resp.data.data;
        this.$Message.success(resp.data.resultDesc);
      } else {
        this.$Message.error(resp.data.resultDesc);
      }
      this.disableFlag = false;
      return true;
    },
    async handleSubmit(formValidate) {
      if (this.isEmpty(formValidate)) {
        return false;
      }
      if (this.tempArr.length === 0) {
        this.treeList.forEach((item) => {
          if (item.children && item.children.length > 0) {
            item.children.forEach((v) => {
              if (v.checked) {
                this.tempArr.push(v);
              }
            });
          } else {
            if (item.checked) {
              this.tempArr.push(item);
            } else {
              this.tempArr = [];
            }
          }
        });
      }
      // 认证
      if (this.userInfo.user.customerType === 1) {
        this.isFlag = true;
        return;
      }
      if (!this.verification(formValidate)) {
        return;
      }
      formValidate.interest = [];
      this.tempArr.forEach((e) => {
        if (e.children && e.children.length > 0) {
          return;
        }
        formValidate.interest.push({
          projectId: e.menuIds || null,
          channelId1: e.id || null,
        });
      });
      const resp = await this.$apis.formServe.webSave({
        auditStatus: 0,
        articleType: 2,
        projectId:
          JSON.parse(sessionStorage.getItem("Table")) &&
          JSON.parse(sessionStorage.getItem("Table")).PlatformEnum[2].id,
        articleId: this.$route.query.id || this.previewData.articleId,
        cover: formValidate.cover,
        monographName: formValidate.title,
        artSummary: formValidate.artSummary,
        press: formValidate.press,
        publishTime: formValidate.publishTime + "-01",
        isbn: formValidate.isbn,
        edition: formValidate.edition,
        pageNumber: formValidate.pageNumber,
        monographLenght: formValidate.monographLenght,
        classifyNumber: formValidate.classifyNumber,
        catalogue: formValidate.catalogue,
        externalUrl: formValidate.externalUrl,
        filePath: formValidate.filePath,
        accessoryName: formValidate.accessoryName,
        articlePushList: this.tempArr.length > 0 ? formValidate.interest : [],
        articleAuthorList:
          formValidate.articleAuthorList &&
          formValidate.articleAuthorList.length > 0
            ? formValidate.articleAuthorList
            : this.tempAutherList, // 其他作者
        synchronizedStatus: formValidate.synchronizedStatus === true ? 1 : 0,
      });
      this.clearField(); // 清空
      if (resp.status === 200) {
        this.$Message.success(resp.data.resultDesc);
      }
      this.$router.push("/user"); //
      console.log(resp);
    },
    // 回显
    async getEcho(f) {
      const resp = await this.$apis.formServe.getEcho({
        articleId: this.$route.query.id,
        articleType: 2,
      });
      const r = resp.data.data;
      // 发布栏目
      resp.data.articlePushList.forEach((itemL) => {
        this.treeList.forEach((itemT) => {
          if (
            itemT.children &&
            itemT.children.length > 0 &&
            itemL.projectId !== undefined
          ) {
            itemT.children.forEach((itemC) => {
              if (itemC.id == itemL.channelId1) {
                itemC["checked"] = true;
              }
            });
          } else {
            if (itemT.menuIds == itemL.projectId) {
              itemT["checked"] = true;
            }
          }
        });
      });
      // 其他作者
      this.tempAutherList = r.articleAuthors;
      for (var i = 0; i < r.articleAuthors.length - 1; i++) {
        f.autherList.push({
          authorName: "",
          authorUnit: "",
          showSelect1: false,
          unitDisabled1: true,
          authorUnitId: "",
          authorId: "",
        });
      }
      r.articleAuthors.forEach((vv, index) => {
        f.autherList[index].authorName = vv.authorName;
        f.autherList[index].authorUnit = vv.authorUnit;
        f.autherList[index].authorId = vv.authorId;
        f.autherList[index].authorUnitId = vv.authorUnitId;
      });
      f.cover = r.cover;
      f.title = r.monographName;
      f.artSummary = r.artSummary;
      f.press = r.press;
      f.publishTime = r.publishTime;
      f.isbn = r.isbn;
      f.edition = r.edition;
      f.pageNumber = r.pageNumber;
      f.monographLenght = r.monographLenght;
      f.classifyNumber = r.classifyNumber;
      // f.catalogue = r.catalogue;
      this.editor.txt.html(r.catalogue); // 重新设置编辑器内容
      f.externalUrl = r.externalUrl;
      f.filePath = r.filePath;
      f.accessoryName = r.accessoryName;
      f.interest = resp.data.articlePushList;
      // f.monographAuthor = r.monographAuthor;
      f.synchronizedStatus = r.synchronizedStatus == 1 ? true : false;
    },
    handleAuther1(list) {
      this.formValidate.articleAuthorList = list;
    },
    changeTime(obj) {
      this.formValidate.publishTime = obj;
    },
    handleSuccess(res, file) {
      const MaxSize = 1024 * 1024 * 20;
      if (file.size > MaxSize) {
        this.$Message.error("文件太大，请重新上传");
      }
      this.formValidate.filePath = res.content.url;
      this.formValidate.accessoryName = file.name;
    },
    // 文件删除处理
    handleFileDel(e) {
      if (
        e.target.className ===
        "ivu-icon ivu-icon-ios-close ivu-upload-list-remove"
      ) {
        this.formValidate.filePath = ""; // 文件路径
        this.formValidate.accessoryName = ""; // 文件名称
      }
    },
    getTrees() {
      this.treeList = this.treeList.map((item) => {
        return {
          title: item.projectName + ' ——',
          expand: true,
          menuIds: item.projectId,
          checked: false,
          children:
            item.columns &&
            item.columns.map((v) => {
              return {
                title: v.columnName,
                menuIds: item.projectId,
                id: v.id,
                checked: false,
              };
            }),
        };
      });
    },
    handleUpload(url) {
      this.formValidate.cover = url; // 图片路径
    },
    changeCheck(item) {
      this.tempArr = item;
    },
    async projectAndColumnListByType(data) {
      const resp = await this.$apis.formServe.projectAndColumnListByType(data);
      this.treeList.push(resp.data[0]);
      this.getTrees();
    },
    clearField() {
      // 清空
      this.formValidate.cover = "";
      this.formValidate.title = "";
      this.formValidate.artSummary = "";
      this.formValidate.press = "";
      this.formValidate.publishTime = "";
      this.formValidate.isbn = "";
      this.formValidate.edition = "";
      this.formValidate.pageNumber = "";
      this.formValidate.monographLenght = "";
      this.formValidate.classifyNumber = "";
      this.formValidate.catalogue = "";
      this.formValidate.externalUrl = "";
      this.formValidate.filePath = "";
      this.formValidate.accessoryName = "";
      // this.formValidate.monographAuthor = "";
      this.formValidate.synchronizedStatus = false;
      this.formValidate.articleAuthorList = [];
    },
    isEmpty(f) {
      if (
        f.cover === "" &&
        f.title === "" &&
        f.artSummary === "" &&
        f.press === "" &&
        f.publishTime === "" &&
        f.isbn === "" &&
        f.edition === "" &&
        f.pageNumber === "" &&
        f.monographLenght === "" &&
        f.catalogue === "" &&
        f.classifyNumber === "" &&
        f.accessoryName === ""
      ) {
        this.$Message.error("不能为空");
        return true;
      } else if (f.cover === "") {
        this.$Message.error("封面不能为空");
        return true;
      } else if (f.title === "") {
        this.$Message.error("标题不能为空");
        return true;
      } else if (f.artSummary === "") {
        this.$Message.error("内容简介不能为空");
        return true;
      } else if (f.press === "") {
        this.$Message.error("出版社不能为空");
        return true;
      } else if (f.publishTime === "") {
        this.$Message.error("出版时间不能为空");
        return true;
      } else if (f.isbn === "") {
        this.$Message.error("ISBN不能为空");
        return true;
      } else if (f.classifyNumber === "") {
        this.$Message.error("分类号不能为空");
        return true;
      } else if (f.classifyNumber.length > 50) {
        this.$Message.error("分类号过长");
        return true;
      } else if (f.edition === "") {
        this.$Message.error("版次不能为空");
        return true;
      } else if (f.pageNumber === "") {
        this.$Message.error("页数不能为空");
        return true;
      } else if (f.monographLenght === "") {
        this.$Message.error("字数不能为空");
        return true;
      } else if (f.catalogue === "") {
        this.$Message.error("目录不能为空");
        return true;
      } else if (f.accessoryName === "") {
        this.$Message.error("附件不能为空");
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ivu-tree .ivu-checkbox-wrapper {
  margin: 0;
}
#text-container ::v-deep table {
  th,
  td {
    width: 200px;
    height: 35px;
    vertical-align: top;
    word-break: break-all;
  }
}
::v-deep #text-container .placeholder {
  color: #999;
}
::v-deep #text-container .w-e-text-container {
  z-index: 1 !important;
}
::v-deep #toolbar-container .w-e-toolbar {
  justify-content: center;
  z-index: 2 !important;
}
::v-deep #toolbar-container {
  margin-top: 17px;
  width: 101%;
}
::v-deep #text-container {
  width: 100%;
  min-height: 300px;
  border: none;
  outline: none;
  color: #515a6e;
}
::v-deep .ivu-tree ul li {
  margin: 0;
  height:37px;
}
::v-deep .ivu-tree  li ul {
  padding:0;
}
::v-deep .ivu-tree-children li {
  display: flex;
  align-items: center;
}
::v-deep .ivu-tree-children li .ivu-tree-arrow {
  display: none;
}
// ::v-deep .ivu-tree-children li .ivu-tree-children li {
//   margin: 30px 40px 0 -60px;
// }
// ::v-deep .ivu-tree-children li .ivu-tree-title {
//   width: 100px;
// }
::v-deep .ivu-radio-group {
  white-space: nowrap;
}
.form-content-container {
  margin: 38px auto 0;
  position: relative;
}
.ivu-input-wrapper ::v-deep input {
  border: none;
  background-color: #fff;
  height: 38px;
}
.ivu-input-wrapper ::v-deep input::-webkit-input-placeholder,
.ivu-input-wrapper ::v-deep textarea::-webkit-input-placeholder {
  color: #999;
}
.ivu-upload ::v-deep .ivu-upload-drag {
  border: none;
  display: flex;
  align-items: center;
}
::v-deep .ivu-upload {
  display: flex;
  align-items: center;
}
.ivu-input-wrapper ::v-deep textarea {
  border: none;
}
.form-container {
  width: 1200px;
  min-height: 1228px;
  background-color: #fff;
  padding: 60px 100px 0;
  margin: 4px auto 30px;
  box-shadow: 0px 7px 15px 1px rgba(22, 26, 100, 0.13);
  .form-item {
    width: 100%;
    min-height: 85px;
    padding: 25px 0;
    border-bottom: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999;
    position: relative;
    &:not(:last-child) {
      border-bottom: 1px solid #dcdcdc;
    }
    .title {
      width: 900px;
      height: 38px;
      line-height: 38px;
      position: relative;
      ::v-deep input {
        font-size: 36px;
      }
      .right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .xing {
      width: 30px;
      color: red;
      text-align: center;
    }
    .left,
    .right {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    .left {
      border-right: 1px solid #dcdcdc;
      width: 355px;
    }
    .right {
      margin-left: 10px;
      width: 642px;
    }
    .year,
    .center,
    .stage {
      display: flex;
      align-items: center;
    }
    .year {
      width: 299px;
      border-right: 1px solid #dcdcdc;
    }
    .center {
      width: 323px;
      border-right: 1px solid #dcdcdc;
    }
    .stage {
      width: 374px;
    }
    .checkbox {
      width: 970px;
      display: flex;
      padding-left: 7px;
      label {
        margin-right: 20px;
      }
    }
    .upload {
      display: flex;
      padding-left: 7px;
      align-items: center;
      label {
        margin-right: 20px;
      }
    }
  }
}
.submit-container {
  height: 70px;
  background-color: #fff;
  text-align: center;
  line-height: 70px;
  .btns {
    margin-left: 800px;
  }
  button {
    width: 90px;
    height: 36px;
    margin: 0 10px;
    border: 1px dashed #333333;
    border-radius: 8px;
    &:last-child {
      background: #00a4ff;
      border: 1px solid #00a4ff;
      border-radius: 8px;
      color: #fff;
    }
  }
}
</style>
